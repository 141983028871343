// Scroll trigger
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

gsap.registerPlugin(ScrollTrigger)

const h1 = document.querySelectorAll('h1 span')
const h2 = document.querySelectorAll('h2 span')

h1.forEach( line => {
    const tl_h1 = gsap.timeline({
        scrollTrigger:{
            trigger: line,
            start: "top 100%",
            end: "top top",
            // scrub: true,
            // markers:true
        }})
        tl_h1.fromTo(line, {
            autoAlpha: 0,
            paddingTop: 50
        }, {
            autoAlpha: 1,
            paddingTop: 0,
            duration: .7,
            delay: 1
        })
})

h2.forEach( line => {
    const tl_h2 = gsap.timeline({
        scrollTrigger:{
            trigger: line,
            start: "top 100%",
            // end: "top top",
            // scrub: true,
            // markers:true
        }})
    tl_h2.fromTo(line, {
        autoAlpha: 0,
        paddingTop: 40
    }, {
        autoAlpha: 1,
        paddingTop: 0,
        duration: .5
    })
})

const p = document.querySelectorAll('p')

p.forEach( p => {
    const tl_p = gsap.timeline({
        scrollTrigger:{
            trigger: p,
            start: "top 100%",
            // end: "top top",
            // scrub: true,
            // markers:true
        }})
        tl_p.fromTo(p, {
            autoAlpha: 0,
            paddingTop: 50
        }, {
            autoAlpha: 1,
            paddingTop: 0,
            duration: .5
        })
})

const flkty = document.querySelectorAll('.flkty-item')
let count = 0;
flkty.forEach( item => {
    count += 0.2
    const tl_flickity_items = gsap.timeline({
        scrollTrigger:{
            trigger: '.carousel',
            start: "top 100%",
            // start: "bottom bottom",
            // end: "top top",
            // scrub: true,
            // markers:true
        }})
    tl_flickity_items.fromTo(item, {
        autoAlpha: 0,
    }, {
        autoAlpha: 1,
        duration: 1,
        delay: count
    })
})
