// Import polyfills for old browsers (cf package.json 'browserslist')

// Force jQuery to be loaded onto the window scope at least once
import $ from './utils/jquery'

// import '../../../node_modules/bootstrap/js/dist/collapse'
import '../../../node_modules/bootstrap/js/dist/offcanvas'
// Import main SCSS theme
// import '../scss/theme.scss'

// Import some modules
// Everything that should be loaded to the whole site should be placed here
import './app'
import './animations/scrollTrigger'

// jQuery Ideative plugins,
// chose which plugins to embed in ./plugins/index.js
// Documentation should be available in each plugin directory
import './plugins/index'

// Main entry function (when DOM loaded)
$(function () {
    /**
     * here you can Asynchronously load any scripts not used on each page.
     * Add them in ./components (add vendors, css and script there)
     */
    // flickity
    if (document.querySelector('.carousel')) {
        import('./components/flickity')
    }
    // add a play button to in placeholder video, on click launch video in a lightbox
    if (document.querySelector('a.id_embedVideo')) {
        import('./components/embed-video')
    }

    $( ".play-btn" ).on( "click", function() {
        $(this).find('a.id_embedVideo').data('id_embedVideo').openPlayer()
    });
})
