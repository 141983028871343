import $ from './utils/jquery'
import g_ from './utils/adaptative'
import {getIntroTl} from "./animations/intro";


/**
 * main initialisation
 * This init is called by adaptative.js when the page is loaded AND when it switches from mobile to desktop or reverse
 */
export const getReady = () => {
    if ($('.intro').length) {
        const introTl = getIntroTl()
        if ($(window).scrollTop() > 100 || !$('body').hasClass('home')) {
            introTl.progress(1)
        } else {
            introTl.play()
        }
    }
}
$(() => {

})
