/*!
 * IDEATIVE Package
 * Manages display and devices
 *
 */

import $ from './jquery'
import UAParser from 'ua-parser-js'
import { getReady } from '../app'
/**
 * Export global vars inside g_ object
 * use g_.[var] in your code to access them
 * g_.isMobileDisplay to check if mobile display or not
 * g_.isMobile to check if mobile device or not
 * g_.isTouch to check if this is a touch device or not
 * g_.isTouchDeviceOnDesktopNav to check if this is a large touch screen (ex. surface)
 *
 * @type {{isMobileDisplay: boolean, isTouch: boolean, isMobile: boolean, isTouchDeviceOnDesktopNav: boolean}}
 * @private
 */
export const g_ = {
    isMobileDisplay: false,
    isMobile: false,
    isTouch: false,
    isTouchDeviceOnDesktopNav: false
}
/**
 * export callbacks to exec when switching mobile->desktop or reverse
 * to use, add to your file
 * import { g_, onResizeDesktopCallbacks, onResizeMobileCallbacks } from '[realtivePathTo: /utils/adaptative.js]'
 * onResizeDesktopCallbacks.push(functionToExecOnDesktop)
 * onResizeMobileCallbacks.push(functionToExecOnMobile)
 *
 * @type {Array}
 */
export const onResizeDesktopCallbacks = []
export const onResizeMobileCallbacks = []

/**
 * know if we are in a resize (true) or load (false)
 * @type {boolean}
 */
let wasInitialized = false
/**
 * get mobile navigation width breakpoint from _variables.scss through _css-variables.scss
 * @type {number}
 */
const mobileNavLimit = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--mobile-limit'))

/**
 * Set to mobile display
 */
function setMobile () {
    g_.isMobileDisplay = true
    document.body.height = window.innerHeight
    getReady()
    for (const i in onResizeMobileCallbacks) {
        onResizeMobileCallbacks[i]()
    }
}

/**
 * Set to desktop display
 */
function setDesktop () {
    g_.isMobileDisplay = false
    getReady()
    for (const i in onResizeDesktopCallbacks) {
        onResizeDesktopCallbacks[i]()
    }
}

/**
 *  Set to mobile OR desktop display on first load and resize
 */
function setDisplayType () {
    // manage ios safari bottom bar (open or not from scroll)
    const winW = $(window).width()
    if (!wasInitialized) {
        wasInitialized = true
        if (winW <= mobileNavLimit) {
            setMobile()
        } else if (winW > mobileNavLimit) {
            setDesktop()
        }
    }
    if (winW <= mobileNavLimit && !g_.isMobileDisplay) {
        setMobile()
    } else if (winW > mobileNavLimit && g_.isMobileDisplay) {
        setDesktop()
    }
    wasInitialized = true
}

$(() => {
    /**
     * get device type
     */
    const parser = new UAParser()
    const isTouchDevice = 'ontouchstart' in document.documentElement
    g_.isMobile = parser.getDevice().type === 'mobile' || ((parser.getDevice().type === 'tablet') && $(window).width() <= mobileNavLimit)
    if ((parser.getDevice().type === 'tablet' || isTouchDevice) && $(window).width() > mobileNavLimit) {
        g_.isTouchDeviceOnDesktopNav = true
    }
    if (g_.isMobile || g_.isTouchDeviceOnDesktopNav) g_.isTouch = true
    // Used to handle the iOs safari bottom bar, must also be updated on resize
    let vh = window.innerHeight * 0.01
    setDisplayType()
    $(window).on('resize', function () {
        vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        setDisplayType()
    })
})
export default [g_, onResizeDesktopCallbacks, onResizeMobileCallbacks]
// to access g_ in Chrome Inspector, type uncomment next line
// window.g_ = g_
