import gsap from 'gsap'

const h1 = document.querySelectorAll('h1 span')

export const getIntroTl = () => {
    const tl = gsap.timeline({paused: true})
    tl.fromTo('.intro', {
        autoAlpha: 0,
        // backgroundSize: '120%'
    }, {
        autoAlpha: 1,
        // backgroundSize: '100%',
        duration: 1
    });
    tl.fromTo('.logo', {
        autoAlpha: 0
    }, {
        autoAlpha: 1,
        duration: 0.5,
    })
    tl.fromTo('.play-btn, .lang-toggler', {
        autoAlpha: 0
    }, {
        autoAlpha: 1,
        duration: 0.6,
    })

    return tl
}
